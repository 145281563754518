import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"
import HeroAbout from "../../components/Hero/HeroAbout"
import getBannerProps from "../../components/Hero/getHeroBannerProps"
import { useStaticQuery, graphql } from "gatsby"

const OurHistorydata = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulGeneric(slug: { eq: "var-historie" }, node_locale: { eq: "nb-NO" }) {
        id
        createdAt
        contentful_id
        updatedAt
        title
        slug
        metadataDescription
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
      }
    }
  `)

  if (data !== undefined && data !== null) return data.contentfulGeneric
  else return null
}

const OurHistory = () => {
  const content = OurHistorydata()
  if (content === null) return <></>
  const heroBannerProps = getBannerProps(content)

  return (
    <>
      <div className="bg-white relative mx-auto max-w-240 ">
        <HeroAbout {...heroBannerProps} />
        <div className="mx-auto max-w-screen md:max-w-2xl px-6 lg:max-w-3xl  xl:max-w-116 mb-48">
          <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
        </div>
      </div>
    </>
  )
}

export default OurHistory
