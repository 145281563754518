import { useState } from "react"
import { graphql } from "gatsby"

import AnchorLink from "react-anchor-link-smooth-scroll"

import SEO, { getSeoProps } from "../components/Common/SEO"
import { HeroTitle } from "../components/Styling/Titles"
import WhoWeAre from "../components/Sections/WhoWeAreSection"
import WhatWeDo from "../components/Sections/WhatWeDoSection"
import Organization from "../components/Sections/OrganizationSection"
import WhatWeMean from "../components/Sections/WhatWeMeanSection"
import OurHistory from "../components/Sections/HistorySection"

const OmOss = ({ location, data }) => {
  const content = data.contentfulGeneric

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "website")

  // Used for drop-down sub-menu in small screens
  // eslint-disable-next-line no-unused-vars
  const [option, setOption] = useState("")

  function handleChange(e) {
    setOption(e.target.value)

    // Smooth-scroll fra nedtrekksmeny for små skjermer
    e.preventDefault()
    const href = "#" + `${e.target.value}`
    const offsetTop = document.querySelector(href).offsetTop + 250
    scroll({ top: offsetTop, behavior: "smooth" })
  }

  return (
    <>
      <SEO {...helmetProps} />
      <div className="bg-white overflow-hidden max-w-240 mx-auto ">
        {/* <div className="bg-white relative max-w-7xl  max- mx-auto px-4 lg:px-8"> */}
        <div className="relative text-center break-normal break-words mt-24  md:mb-2 md:px-6">
          <HeroTitle>{content.title}</HeroTitle>
        </div>
        {/* </div> */}

        {/* Sub-menu */}
        <div className="flex md:hidden justify-center mt-20  ">
          <select
            defaultValue={"DEFAULT"}
            onChange={handleChange}
            id="location"
            name="location"
            className=" w-80 px-8 text-left text-button15 border-gray-300 focus:outline-none sm:text-sm  rounded-3xl bg-white ring-1 ring-black  focus:ring-none   focus:border-black"
          >
            <option value="DEFAULT">Hvem er vi</option>
            <option value="hva-gjor-vi">Hva gjør vi</option>
            <option value="hva-mener-vi">Hva mener vi</option>
            <option value="var-historie">Vår historie</option>
            <option value="organisasjon">Vår organisasjon</option>
          </select>
        </div>

        <div className="hidden md:block bg-transparent mx-auto max-w-3xl mt-20 ">
          <div className="flex text-button15 text-sort-200 font-normal justify-around sm:flex-row sm:flex-wrap">
            <AnchorLink
              href={"#hvem-er-vi"}
              className="bg-transparent active:bg-knapplysgronn-500 hover:bg-knapplysgronn-500 hover:text-squash-500 focus:bg-knapplysgronn-500  py-2 px-4 focus:border-transparent border-none hover:border-transparent rounded-full 
                "
              role="menuitem"
              // autofocus="true"
            >
              Hvem vi er
            </AnchorLink>
            <AnchorLink
              href={"#hva-gjor-vi"}
              className="bg-transparent text-sort-200 active:bg-knapplysgronn-500  hover:bg-knapplysgronn-500  hover:text-squash-500 font-normal    focus:bg-knapplysgronn-500 py-2 px-4  border-none hover:border-transparent rounded-full"
              role="menuitem"
            >
              Hva vi gjør
            </AnchorLink>
            <AnchorLink
              href={"#hva-mener-vi"}
              className="bg-transparent text-sort-200 active:bg-knapplysgronn-500  hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal    focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
              role="menuitem"
            >
              Hva vi mener
            </AnchorLink>
            <AnchorLink
              href={"#var-historie"}
              className="bg-transparent text-sort-200 hover:bg-knapplysgronn-500 hover:text-squash-500 font-normal focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
              role="menuitem"
            >
              Vår historie
            </AnchorLink>
            <AnchorLink
              href={"#organisasjon"}
              className="bg-transparent text-sort-200 hover:bg-knapplysgronn-500  hover:text-squash-500 font-normal focus:bg-knapplysgronn-500 py-2 px-4 border border-none hover:border-transparent rounded-full"
              role="menuitem"
            >
              Vår organisasjon
            </AnchorLink>
          </div>
        </div>
        <div className="relative max-w-screen-sm mx-auto text-center">
          {/* Ingressen er ikke med i design - kommentert ut inntil videre */}
          {/* <div className=" max-w-3xl px-8  mt-16 ">
          <Ingress>{content.ingress.ingress}</Ingress>
        </div> */}
        </div>
        <div className="mt-20">
          <div id="hvem-er-vi">
            <WhoWeAre />
          </div>

          <div id="hva-gjor-vi">
            <WhatWeDo />
          </div>

          <div id="hva-mener-vi">
            <WhatWeMean />
          </div>

          <div id="var-historie">
            <OurHistory />
          </div>
          <div id="organisasjon">
            <Organization />
          </div>
        </div>
      </div>
    </>
  )
}

export default OmOss
export const query = graphql`
  {
    contentfulGeneric(slug: { eq: "om-gartnerhallen" }, node_locale: { eq: "nb-NO" }) {
      id
      createdAt
      contentful_id
      updatedAt
      title
      slug
      metadataDescription
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
    }
  }
`
