import { HeroRightFarmer } from "./HeroImageHandlers"
import { HeroAboutTitle } from "../Styling/Titles"
import { Ingress } from "../Styling/Body"

import HeroBannerProps from "../Hero/HeroTypes"

const HeroAbout = (props: HeroBannerProps) => {
  return (
    <>
      <header>
        <div className=" max-w-240 mx-auto">
          <div className="grid grid-flow-col grid-cols-1 grid-rows-2 md:grid-rows-1 md:grid-cols-2  md:col-start-2  lg:grid-rows-1 lg:grid-cols-2 gap-0   ">
            <div className="flex justify-center bg-knapplysgronn-500 order-last md:order-first ">
              <div className="text-center break-normal max-w-m lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl my-auto mx-auto">
                {/* <div className="mt-8 sm:mt-20"> */}
                <span className="inline-block align-middle pl-1">
                  <HeroAboutTitle>{props.title}</HeroAboutTitle>
                </span>
              </div>
            </div>
            <div className=" min-h-44 xl:max-h-192 ">
              <HeroRightFarmer {...props} />
            </div>
          </div>
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="px-4 sm:px-0 max-w-s sm:max-w-lg md:max-w-xl xl:max-w-84 mx-auto mt-20 ">
            <Ingress>{props.ingress}</Ingress>
          </div>
        </div>
      </header>
    </>
  )
}

export default HeroAbout
